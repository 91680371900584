import { Box, Button, Heading, Stack, Text } from '@chakra-ui/react';
import NextImage from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import Layout from '../../components/layout';
import Seo from '../../components/seo';

export default function Custom404() {
	const [show, setShow] = useState( false );
	const seo = {
		metaTitle: '404',
		metaDescription: 'Pagina non trovata',
		shareImage: null,
		article: false
	};

	return (
		<Layout>
			<Seo seo={ seo } />
			<Stack as="article" bg="bg-surface" maxW={ { base: 'xl', md: '7xl' } } mx="auto" px={ { base: '6', md: '8', } } my={ 24 } textAlign={ 'center' } spacing={ 8 }>
				<Heading as="h1" fontSize="6xl" variant="brand1" mb="4" fontWeight={ 'normal' }>
					Complimenti! Hai trovato una pagina 404 🎉
				</Heading>
				<Text>Purtroppo la pagina che stavi cercando non c&apos;è più o è stata spostata. In compenso hai trovato un easter-egg. Goditelo!</Text>
				{ !show && (
					<Button onClick={ () => setShow( !show ) } mt={ 4 } alignSelf={ 'center' }>
						Scopri di cosa si tratta
					</Button>
				) }
				{ show && (
					<>
						<Text fontSize="xl" fontWeight="bold">Hai trovato Pixel, il mio gatto. Ti porterà fortuna nel trovare la pagina che stai cercando</Text>
						<Box borderRadius="full" overflow="hidden" alignSelf="center">
							<NextImage src="/pixel1.webp" width={ 300 } height={ 300 } alt="Pixel" />
						</Box>
						<Button as={ Link } href="/" alignSelf={ 'center' }>
							Torna alla home
						</Button>
					</>
				) }
			</Stack>
		</Layout>
	);
}
